import {
   SET_EA_MODE,
   SET_ZOOM,
   SET_EA_INFO_OPEN
} from './index';


export const setEaMode = (payload) => {
   const { eaMode } = payload;

   localStorage.setItem("eaMode",  eaMode);

   return {
       type: SET_EA_MODE,
       eaMode
   }
}


export const setZoom = (payload) => {
   const { zoom } = payload;
   return {
       type: SET_ZOOM,
       zoom
   }
}