import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { Button, Form, Input, Select, notification, Spin } from 'antd';
import { countries } from '../../countries';
import { closePopup } from '../../actions/popup';
import userEmail from './userEmail';
import '../../pages/coreSolutions/coreSolutions.scss';


const mapDispatchToProps = dispatch => ({
  closePopup: () => {
    return dispatch(closePopup());
  }
})

const BookConsultation = (props) => {

  const {
    closePopup,
  } = props;

  const { TextArea } = Input;
  const [isLoading, setIsLoading] = useState(false);
  const [screenWidth, setScreenWidth] = useState(window.innerWidth);
  const [inputSize, setInputSize] = useState('large');

  useEffect(() => {
    const handleResize = () => {
      setScreenWidth(window.innerWidth);
    };

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  useEffect(() => {
    if(screenWidth > 640) {
      setInputSize('large')
    } else {
      setInputSize('medium')
    }
  }, [screenWidth])


  const onFinish = (values) => {

    setIsLoading(true);

    const url = 'https://s01.blukite.systems/dev/api/api/1/send-email/html';

    const sendEmailToAdmin = () => {
    return fetch(url, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        email: 'info@blukite.finance', subject: 'BKF',
        html: `
  <!DOCTYPE html PUBLIC "-//W3C//DTD XHTML 1.0 Transitional//EN" "https://www.w3.org/TR/xhtml1/DTD/xhtml1-transitional.dtd">
<html xmlns="https://www.w3.org/1999/xhtml" xmlns:v="urn:schemas-microsoft-com:vml" xmlns:o="urn:schemas-microsoft-com:office:office">

<head>
 <meta charset="UTF-8" />
 <meta http-equiv="Content-Type" content="text/html; charset=utf-8" />
 <!--[if !mso]><! -->
 <meta http-equiv="X-UA-Compatible" content="IE=edge" />
 <!--<![endif]-->
 <meta name="viewport" content="width=device-width, initial-scale=1.0" />
 <meta name="format-detection" content="telephone=no" />
 <meta name="format-detection" content="date=no" />
 <meta name="format-detection" content="address=no" />
 <meta name="format-detection" content="email=no" />
 <meta name="x-apple-disable-message-reformatting" />
 <link href="https://fonts.googleapis.com/css?family=Inter:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900" rel="stylesheet" />
 <title>Evelyn Green</title>
 <!-- Made with Postcards by Designmodo https://designmodo.com/postcards -->
 <style>
 html,
         body {
             margin: 0 !important;
             padding: 0 !important;
             min-height: 100% !important;
             width: 100% !important;
             -webkit-font-smoothing: antialiased;
         }
 
         * {
             -ms-text-size-adjust: 100%;
         }
 
         #outlook a {
             padding: 0;
         }
 
         .ReadMsgBody,
         .ExternalClass {
             width: 100%;
         }
 
         .ExternalClass,
         .ExternalClass p,
         .ExternalClass td,
         .ExternalClass div,
         .ExternalClass span,
         .ExternalClass font {
             line-height: 100%;
         }
 
         div[style*="margin: 14px 0"],
         div[style*="margin: 16px 0"] {
             margin: 0 !important;
         }
 
         table,
         td,
         th {
             mso-table-lspace: 0 !important;
             mso-table-rspace: 0 !important;
             border-collapse: collapse;
         }
 
         body, td, th, p, div, li, a, span {
             -webkit-text-size-adjust: 100%;
             -ms-text-size-adjust: 100%;
             mso-line-height-rule: exactly;
         }
 
         img {
             border: 0;
             outline: none;
             line-height: 100%;
             text-decoration: none;
             -ms-interpolation-mode: bicubic;
         }
 
         a[x-apple-data-detectors] {
             color: inherit !important;
             text-decoration: none !important;
         }
 
         .pc-gmail-fix {
             display: none;
             display: none !important;
         }
 
         @media (min-width: 621px) {
             .pc-lg-hide {
                 display: none;
             } 
 
             .pc-lg-bg-img-hide {
                 background-image: none !important;
             }
         }
 </style>
 <style>
 @media (max-width: 620px) {
 .pc-project-body {min-width: 0px !important;}
 .pc-project-container {width: 100% !important;}
 .pc-sm-hide {display: none !important;}
 .pc-sm-bg-img-hide {background-image: none !important;}
 .pc-w620-padding-20-0-0-0 {padding: 20px 0px 0px 0px !important;}
 .pc-w620-padding-10-0-10-0 {padding: 10px 0px 10px 0px !important;}
 table.pc-w620-spacing-0-0-10-0 {margin: 0px 0px 10px 0px !important;}
 td.pc-w620-spacing-0-0-10-0,th.pc-w620-spacing-0-0-10-0{margin: 0 !important;padding: 0px 0px 10px 0px !important;}
 .pc-w620-fontSize-20px {font-size: 20px !important;}
 .pc-w620-padding-10-30-10-30 {padding: 10px 30px 10px 30px !important;}
 .pc-w620-padding-30-30-30-30 {padding: 30px 30px 30px 30px !important;}
 
 .pc-w620-gridCollapsed-1 > tbody,.pc-w620-gridCollapsed-1 > tbody > tr,.pc-w620-gridCollapsed-1 > tr {display: inline-block !important;}
 .pc-w620-gridCollapsed-1.pc-width-fill > tbody,.pc-w620-gridCollapsed-1.pc-width-fill > tbody > tr,.pc-w620-gridCollapsed-1.pc-width-fill > tr {width: 100% !important;}
 .pc-w620-gridCollapsed-1.pc-w620-width-fill > tbody,.pc-w620-gridCollapsed-1.pc-w620-width-fill > tbody > tr,.pc-w620-gridCollapsed-1.pc-w620-width-fill > tr {width: 100% !important;}
 .pc-w620-gridCollapsed-1 > tbody > tr > td,.pc-w620-gridCollapsed-1 > tr > td {display: block !important;width: auto !important;padding-left: 0 !important;padding-right: 0 !important;}
 .pc-w620-gridCollapsed-1.pc-width-fill > tbody > tr > td,.pc-w620-gridCollapsed-1.pc-width-fill > tr > td {width: 100% !important;}
 .pc-w620-gridCollapsed-1.pc-w620-width-fill > tbody > tr > td,.pc-w620-gridCollapsed-1.pc-w620-width-fill > tr > td {width: 100% !important;}
 .pc-w620-gridCollapsed-1 > tbody > .pc-grid-tr-first > .pc-grid-td-first,pc-w620-gridCollapsed-1 > .pc-grid-tr-first > .pc-grid-td-first {padding-top: 0 !important;}
 .pc-w620-gridCollapsed-1 > tbody > .pc-grid-tr-last > .pc-grid-td-last,pc-w620-gridCollapsed-1 > .pc-grid-tr-last > .pc-grid-td-last {padding-bottom: 0 !important;}
 
 .pc-w620-gridCollapsed-0 > tbody > .pc-grid-tr-first > td,.pc-w620-gridCollapsed-0 > .pc-grid-tr-first > td {padding-top: 0 !important;}
 .pc-w620-gridCollapsed-0 > tbody > .pc-grid-tr-last > td,.pc-w620-gridCollapsed-0 > .pc-grid-tr-last > td {padding-bottom: 0 !important;}
 .pc-w620-gridCollapsed-0 > tbody > tr > .pc-grid-td-first,.pc-w620-gridCollapsed-0 > tr > .pc-grid-td-first {padding-left: 0 !important;}
 .pc-w620-gridCollapsed-0 > tbody > tr > .pc-grid-td-last,.pc-w620-gridCollapsed-0 > tr > .pc-grid-td-last {padding-right: 0 !important;}
 
 .pc-w620-tableCollapsed-1 > tbody,.pc-w620-tableCollapsed-1 > tbody > tr,.pc-w620-tableCollapsed-1 > tr {display: block !important;}
 .pc-w620-tableCollapsed-1.pc-width-fill > tbody,.pc-w620-tableCollapsed-1.pc-width-fill > tbody > tr,.pc-w620-tableCollapsed-1.pc-width-fill > tr {width: 100% !important;}
 .pc-w620-tableCollapsed-1.pc-w620-width-fill > tbody,.pc-w620-tableCollapsed-1.pc-w620-width-fill > tbody > tr,.pc-w620-tableCollapsed-1.pc-w620-width-fill > tr {width: 100% !important;}
 .pc-w620-tableCollapsed-1 > tbody > tr > td,.pc-w620-tableCollapsed-1 > tr > td {display: block !important;width: auto !important;}
 .pc-w620-tableCollapsed-1.pc-width-fill > tbody > tr > td,.pc-w620-tableCollapsed-1.pc-width-fill > tr > td {width: 100% !important;}
 .pc-w620-tableCollapsed-1.pc-w620-width-fill > tbody > tr > td,.pc-w620-tableCollapsed-1.pc-w620-width-fill > tr > td {width: 100% !important;}
 }
 </style>
 <!--[if !mso]><! -->
 <style>
 @media all { @font-face { font-family: 'Inter'; font-style: normal; font-weight: 300; src: url('https://fonts.gstatic.com/s/inter/v13/UcCO3FwrK3iLTeHuS_fvQtMwCp50KnMw2boKoduKmMEVuOKfAZFhiA.woff2') format('woff2'); } @font-face { font-family: 'Inter'; font-style: normal; font-weight: 100; src: url('https://fonts.gstatic.com/s/inter/v13/UcCO3FwrK3iLTeHuS_fvQtMwCp50KnMw2boKoduKmMEVuLyeAZFhiA.woff2') format('woff2'); } @font-face { font-family: 'Inter'; font-style: normal; font-weight: 400; src: url('https://fonts.gstatic.com/s/inter/v13/UcCO3FwrK3iLTeHuS_fvQtMwCp50KnMw2boKoduKmMEVuLyfAZFhiA.woff2') format('woff2'); } @font-face { font-family: 'Inter'; font-style: normal; font-weight: 500; src: url('https://fonts.gstatic.com/s/inter/v13/UcCO3FwrK3iLTeHuS_fvQtMwCp50KnMw2boKoduKmMEVuI6fAZFhiA.woff2') format('woff2'); } @font-face { font-family: 'Inter'; font-style: normal; font-weight: 200; src: url('https://fonts.gstatic.com/s/inter/v13/UcCO3FwrK3iLTeHuS_fvQtMwCp50KnMw2boKoduKmMEVuDyfAZFhiA.woff2') format('woff2'); } @font-face { font-family: 'Inter'; font-style: normal; font-weight: 700; src: url('https://fonts.gstatic.com/s/inter/v13/UcCO3FwrK3iLTeHuS_fvQtMwCp50KnMw2boKoduKmMEVuFuYAZFhiA.woff2') format('woff2'); } @font-face { font-family: 'Inter'; font-style: normal; font-weight: 600; src: url('https://fonts.gstatic.com/s/inter/v13/UcCO3FwrK3iLTeHuS_fvQtMwCp50KnMw2boKoduKmMEVuGKYAZFhiA.woff2') format('woff2'); } @font-face { font-family: 'Inter'; font-style: normal; font-weight: 900; src: url('https://fonts.gstatic.com/s/inter/v13/UcCO3FwrK3iLTeHuS_fvQtMwCp50KnMw2boKoduKmMEVuBWYAZFhiA.woff2') format('woff2'); } @font-face { font-family: 'Inter'; font-style: normal; font-weight: 800; src: url('https://fonts.gstatic.com/s/inter/v13/UcCO3FwrK3iLTeHuS_fvQtMwCp50KnMw2boKoduKmMEVuDyYAZFhiA.woff2') format('woff2'); } }
 </style>
 <!--<![endif]-->
 <!--[if mso]>
    <style type="text/css">
        .pc-font-alt {
            font-family: Arial, Helvetica, sans-serif !important;
        }
    </style>
    <![endif]-->
 <!--[if gte mso 9]>
    <xml>
        <o:OfficeDocumentSettings>
            <o:AllowPNG/>
            <o:PixelsPerInch>96</o:PixelsPerInch>
        </o:OfficeDocumentSettings>
    </xml>
    <![endif]-->
</head>

<body class="pc-font-alt" style="width: 100% !important;min-height: 100% !important;margin: 0 !important;padding: 0 !important;line-height: 1.5;color: #2D3A41;mso-line-height-rule: exactly;-webkit-font-smoothing: antialiased;-webkit-text-size-adjust: 100%;-ms-text-size-adjust: 100%;font-variant-ligatures: normal;text-rendering: optimizeLegibility;-moz-osx-font-smoothing: grayscale;background-color: #eff7fc;" bgcolor="#eff7fc">
 <table class="pc-project-body" style="table-layout: fixed;min-width: 600px;background-color:#eff7fc;" bgcolor="#eff7fc" width="100%" border="0" cellspacing="0" cellpadding="0" role="presentation">
  <tr>
   <td align="center" valign="top">
    <table class="pc-project-container" style="width: 600px; max-width: 600px;" width="600" align="center" border="0" cellpadding="0" cellspacing="0" role="presentation">
     <tr>
      <td class="pc-w620-padding-20-0-0-0" style="padding: 20px 0px 20px 0px;" align="left" valign="top">
       <table border="0" cellpadding="0" cellspacing="0" role="presentation" width="100%" style="width: 100%;">
        <tr>
         <td valign="top">
          <!-- BEGIN MODULE: Title -->
          <table width="100%" border="0" cellspacing="0" cellpadding="0" role="presentation">
           <tr>
            <td class="pc-w620-spacing-0-0-10-0" style="padding: 0px 0px 10px 0px;">
             <table width="100%" border="0" cellspacing="0" cellpadding="0" role="presentation">
              <tr>
               <td valign="top" class="pc-w620-padding-10-0-10-0" style="padding: 5px 40px 5px 40px;border-radius: 1px 1px 1px 1px;background-color: #152a37;" bgcolor="#152a37">
                <table width="100%" border="0" cellpadding="0" cellspacing="0" role="presentation">
                 <tr>
                  <td align="center" valign="top">
                    <img src="https://blukite.finance/images/logo-bold-light.png" class="" width="130" height="68" alt="" style="display: block;border: 0;outline: 0;line-height: 100%;-ms-interpolation-mode: bicubic;width:130px;height: auto;max-width: 100%; padding: 10px 0;" />
                  </td>
                 </tr>
                </table>
               </td>
              </tr>
             </table>
            </td>
           </tr>
          </table>
          <!-- END MODULE: Title -->
         </td>
        </tr>
        <tr>
         <td valign="top">
          <!-- BEGIN MODULE: Text -->
          <table width="100%" border="0" cellspacing="0" cellpadding="0" role="presentation">
           <tr>
            <td class="pc-w620-spacing-0-0-10-0" style="padding: 0px 0px 10px 0px;">
             <table width="100%" border="0" cellspacing="0" cellpadding="0" role="presentation">
              <tr>
               <td valign="top" class="pc-w620-padding-10-30-10-30" style="padding: 20px 40px 20px 40px;border-radius: 1px 1px 1px 1px;background-color: #ffffff;" bgcolor="#ffffff">
                <table border="0" cellpadding="0" cellspacing="0" role="presentation">
                 <tr>
                  <td valign="top" class="pc-font-alt pc-w620-fontSize-20px" style="mso-line-height: exactly;line-height: 120%;letter-spacing: -0.1px;font-family: Inter, Arial, Helvetica, sans-serif;font-size: 24px;font-weight: normal;color: #333333;font-variant-ligatures: normal;">
                   <div><span>Consultation booking</span>
                   </div>
                  </td>
                 </tr>
                </table>
               </td>
              </tr>
             </table>
            </td>
           </tr>
          </table>
          <!-- END MODULE: Text -->
         </td>
        </tr>
        <tr>
         <td valign="top">
          <!-- BEGIN MODULE: Text -->
          <table width="100%" border="0" cellspacing="0" cellpadding="0" role="presentation">
           <tr>
            <td class="pc-w620-spacing-0-0-10-0" style="padding: 0px 0px 10px 0px;">
             <table width="100%" border="0" cellspacing="0" cellpadding="0" role="presentation">
              <tr>
               <td valign="top" class="pc-w620-padding-30-30-30-30" style="padding: 40px 40px 40px 40px;border-radius: 1px 1px 1px 1px;background-color: #ffffff;" bgcolor="#ffffff">
                <table width="100%" border="0" cellpadding="0" cellspacing="0" role="presentation">
                 <tr>
                  <td>
                   <table class="pc-width-fill pc-w620-tableCollapsed-0" border="0" cellpadding="0" cellspacing="0" role="presentation" width="100%" style="border-collapse: separateborder-spacing: 0width: 100%;">
                    <tbody>
                     <tr bgcolor="#ffffff" style="background-color: #ffffff;">
                      <td width="130" align="left" valign="top" style="padding: 3px 0px 3px 0px;border-top: 1px solid #ffffff; border-bottom: 1px solid #ffffff; border-left: 1px solid #ffffff;">
                       <table border="0" cellpadding="0" cellspacing="0" role="presentation">
                        <tr>
                         <td valign="top" class="pc-font-alt" style="mso-line-height: exactly;line-height: 140%;letter-spacing: -0.2px;font-family: Inter, Arial, Helvetica, sans-serif;font-size: 16px;font-weight: 600;color: #333333;font-variant-ligatures: normal;">
                          <div><span>Name:</span>
                          </div>
                         </td>
                        </tr>
                       </table>
                      </td>
                      <td align="left" valign="top" style="padding: 3px 0px 3px 0px;border-top: 1px solid #ffffff; border-right: 1px solid #ffffff; border-bottom: 1px solid #ffffff;">
                       <table border="0" cellpadding="0" cellspacing="0" role="presentation">
                        <tr>
                         <td valign="top" class="pc-font-alt" style="mso-line-height: exactly;line-height: 140%;letter-spacing: -0.2px;font-family: Inter, Arial, Helvetica, sans-serif;font-size: 16px;font-weight: normal;color: #333333;font-variant-ligatures: normal;">
                          <div><span style="font-weight: 400;font-style: normal;">${values.firstName}</span>
                          </div>
                         </td>
                        </tr>
                       </table>
                      </td>
                     </tr>
                     <tr bgcolor="#ffffff" style="background-color: #ffffff;">
                      <td width="130" align="left" valign="top" style="padding: 3px 0px 3px 0px;border-top: 1px solid #ffffff; border-bottom: 1px solid #ffffff; border-left: 1px solid #ffffff;">
                       <table border="0" cellpadding="0" cellspacing="0" role="presentation">
                        <tr>
                         <td valign="top" class="pc-font-alt" style="mso-line-height: exactly;line-height: 140%;letter-spacing: -0.2px;font-family: Inter, Arial, Helvetica, sans-serif;font-size: 16px;font-weight: 600;color: #333333;font-variant-ligatures: normal;">
                          <div><span>Email:</span>
                          </div>
                         </td>
                        </tr>
                       </table>
                      </td>
                      <td align="left" valign="top" style="padding: 3px 0px 3px 0px;border-top: 1px solid #ffffff; border-right: 1px solid #ffffff; border-bottom: 1px solid #ffffff;">
                       <table border="0" cellpadding="0" cellspacing="0" role="presentation">
                        <tr>
                         <td valign="top" class="pc-font-alt" style="mso-line-height: exactly;line-height: 140%;letter-spacing: -0.2px;font-family: Inter, Arial, Helvetica, sans-serif;font-size: 16px;font-weight: normal;color: #333333;font-variant-ligatures: normal;">
                          <div><span style="font-weight: 400;font-style: normal;">${values.email}</span>
                          </div>
                         </td>
                        </tr>
                       </table>
                      </td>
                     </tr>
                     <tr>
                      <td width="130" align="left" valign="top" style="padding: 3px 0px 3px 0px;">
                       <table border="0" cellpadding="0" cellspacing="0" role="presentation">
                        <tr>
                         <td valign="top" class="pc-font-alt" style="mso-line-height: exactly;line-height: 140%;letter-spacing: -0.2px;font-family: Inter, Arial, Helvetica, sans-serif;font-size: 16px;font-weight: 600;color: #333333;font-variant-ligatures: normal;">
                          <div><span>Responsibility:</span>
                          </div>
                         </td>
                        </tr>
                       </table>
                      </td>
                      <td align="left" valign="top" style="padding: 3px 0px 3px 0px;">
                       <table border="0" cellpadding="0" cellspacing="0" role="presentation">
                        <tr>
                         <td valign="top" class="pc-font-alt" style="mso-line-height: exactly;line-height: 140%;letter-spacing: -0.2px;font-family: Inter, Arial, Helvetica, sans-serif;font-size: 16px;font-weight: normal;color: #333333;font-variant-ligatures: normal;">
                          <div><span style="font-weight: 400;font-style: normal;">${values.responsibility}</span>
                          </div>
                         </td>
                        </tr>
                       </table>
                      </td>
                     </tr>
                     <tr>
                      <td width="130" height="13" align="left" valign="top" style="padding: 3px 0px 3px 0px;">
                       <table border="0" cellpadding="0" cellspacing="0" role="presentation">
                        <tr>
                         <td valign="top" class="pc-font-alt" style="mso-line-height: exactly;line-height: 140%;letter-spacing: -0.2px;font-family: Inter, Arial, Helvetica, sans-serif;font-size: 16px;font-weight: 600;color: #333333;font-variant-ligatures: normal;">
                          <div><span>Country:</span>
                          </div>
                         </td>
                        </tr>
                       </table>
                      </td>
                      <td height="13" align="left" valign="top" style="padding: 3px 0px 3px 0px;">
                       <table border="0" cellpadding="0" cellspacing="0" role="presentation">
                        <tr>
                         <td valign="top" class="pc-font-alt" style="mso-line-height: exactly;line-height: 140%;letter-spacing: -0.2px;font-family: Inter, Arial, Helvetica, sans-serif;font-size: 16px;font-weight: normal;color: #333333;font-variant-ligatures: normal;">
                          <div><span style="font-weight: 400;font-style: normal;">${values.country}</span>
                          </div>
                         </td>
                        </tr>
                       </table>
                      </td>
                     </tr>
                     <tr>
                      <td width="130" height="13" align="left" valign="top" style="padding: 3px 0px 3px 0px;">
                       <table border="0" cellpadding="0" cellspacing="0" role="presentation">
                        <tr>
                         <td valign="top" class="pc-font-alt" style="mso-line-height: exactly;line-height: 140%;letter-spacing: -0.2px;font-family: Inter, Arial, Helvetica, sans-serif;font-size: 16px;font-weight: 600;color: #333333;font-variant-ligatures: normal;">
                          <div><span>Expectations:</span>
                          </div>
                         </td>
                        </tr>
                       </table>
                      </td>
                      <td height="13" align="left" valign="top" style="padding: 3px 0px 3px 0px;">
                       <table border="0" cellpadding="0" cellspacing="0" role="presentation">
                        <tr>
                         <td valign="top" class="pc-font-alt" style="mso-line-height: exactly;line-height: 140%;letter-spacing: -0.2px;font-family: Inter, Arial, Helvetica, sans-serif;font-size: 16px;font-weight: normal;color: #333333;font-variant-ligatures: normal;">
                          <div><span style="font-weight: 400;font-style: normal;">${values.expectations}</span>
                          </div>
                         </td>
                        </tr>
                       </table>
                      </td>
                     </tr>
                    </tbody>
                   </table>
                  </td>
                 </tr>
                </table>
               </td>
              </tr>
             </table>
            </td>
           </tr>
          </table>
          <!-- END MODULE: Text -->
         </td>
        </tr>
       </table>
      </td>
     </tr>
    </table>
   </td>
  </tr>
 </table>
 <!-- Fix for Gmail on iOS -->
 <div class="pc-gmail-fix" style="white-space: nowrap; font: 15px courier; line-height: 0;">&nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;
 </div>
</body>
</html>

      `
      }),
    })
  };

const sendEmailToUser = () => {
  return fetch(url, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify({
      email: values.email,
      subject: 'Thank You for Your Enquiry with Blukite Finance',
      html: userEmail()
    })
  });
}

sendEmailToAdmin()
  .then(response => {
    if (!response.ok) {
      throw new Error('Network response was not ok');
    }
    return response.json();
  })
  .then(data => {
    return sendEmailToUser();
  })
  .then(response => {
    if (!response.ok) {
      throw new Error('Network response was not ok');
    }
    return response.json();
  })
  .then(() => {
    notification.success({
      message: 'Sent!',
      description: 'Your message has been sent successfully',
    });
  })
  .catch((error) => {
    console.log(error);
    notification.error({
      message: 'Error!',
      description: 'Something went wrong',
    });
  })
  .finally(() => {
    setIsLoading(false);
    closePopup();
  });

};



  const onFinishFailed = (errorInfo) => {
    console.log('Failed:', errorInfo);
  };


  return (

    <div className='text-base font-light'>
      {isLoading && <div className='w-full h-full absolute top-0 left-0 bg-transparentwhite z-40 rounded-lg'><Spin className=' absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2' size='large' /></div>}
      <h1 className='text-xl font-normal'>Connect with Blukite Finance</h1>
      <p className=''>Fill out the contact form below and one of our experts will reach out to you.</p>

      <Form
        layout="vertical"
        name="basic"
        onFinish={onFinish}
        onFinishFailed={onFinishFailed}
        autoComplete="off"
        className='mt-5'
      >
        <Form.Item
          label="First and Last Name"
          name="firstName"
          rules={[
            {
              required: true,
              message: 'Please input your first name',
            },
          ]}
        >
          <Input size={inputSize} placeholder='John' />
        </Form.Item>

        <Form.Item
          label="Email"
          name="email"
          rules={[
            {
              required: true,
              type: "email",
              message: "The input is not valid E-mail!",
            },
          ]}
        >
          <Input size={inputSize} placeholder='simple@example.com' />
        </Form.Item>

        <Form.Item
          label="Responsibility"
          name="responsibility"
          rules={[
            {
              required: true,
              message: 'Please input your responsibility',
            },
          ]}
        >
          <Input size={inputSize} placeholder='Chief Executive' />
        </Form.Item>

        <Form.Item
          label="Country of operation"
          name="country"
          rules={[
            {
              required: true,
              message: 'Please input country of operation',
            },
          ]}
        >
          <Select
            size={inputSize}
            showSearch
            options={countries.map((item) => ({
              value: item.name,
              label: item.name,
            }))}

          />
        </Form.Item>
{/* 
        <Form.Item
          label="Planned launch?"
          name="launch"
          rules={[
            {
              required: true,
              message: 'Please input planned launch',
            },
          ]}
        >
          <Select
            showSearch
            size={inputSize}
            onChange={(x) => console.log('x', x)}
            options={[
              {
                value: 'Q1_2024',
                label: 'Q1 2024',
              },
              {
                value: 'Q2_2024',
                label: 'Q2 2024',
              },
              {
                value: 'Q3_2024',
                label: 'Q3 2024',
              },
              {
                value: 'Q4_2024',
                label: 'Q4 2024',
              },
              {
                value: 'Q1_2025',
                label: 'Q1 2025',
              },
            ]}
          />
        </Form.Item> */}

        <Form.Item
          label="Please share what you are most interested in seeing during the consultation"
          name="expectations"
          className='mt-4'
        >
          <TextArea size='large'
            count={{
              show: true,
              max: 1000,
              strategy: (txt) => txt.length,
              exceedFormatter: (txt, { max }) => txt.slice(0, max)
            }} rows={4} />
        </Form.Item>


        <Form.Item
          wrapperCol={{
          }}
        >

          <Button type="primary" htmlType="submit" className="customButton rounded w-full sm:w-fit mt-3 py-5 px-7 text-lg cursor-pointer hover:bg-brightBlue justify-between transition whitespace-nowrap flex-nowrap bg-blue text-white w-fit flex items-center gap-5">
            Submit
            <img className="w-7" src="../images/ico/rightArrow-light.svg" alt="arrow" />
          </Button>
        </Form.Item>
      </Form>
    </div>
  )
}

export default connect(
  null,
  mapDispatchToProps
)(BookConsultation);