import React, { useEffect, useState, useRef } from 'react';
import { connect } from 'react-redux';
import {setCurrentSpeaker, toggleIsSpeaking} from '../../actions/speaker';
import { basePath } from '../../routes';


const mapStateToProps = state => ({
    ...state.ea,
    ...state.speaker,
});

const mapDispatchToProps = dispatch => ({
    setCurrentSpeaker: (payload) => {
        return dispatch(setCurrentSpeaker(payload));
    },
    toggleIsSpeaking: (isSpeaking) => {
        return dispatch(toggleIsSpeaking(isSpeaking));
    }
})

const Speaker = ({ id, activeSpeakerId, activeIsSpeaking, toggleIsSpeaking, text, setCurrentSpeaker, eaMode, isRight }) => {
    const [isSpeaking, setIsSpeaking] = useState(false);

    useEffect(() => {
        if(activeSpeakerId !== id){
            setIsSpeaking(false);
        }
    }, [activeSpeakerId])

    const toggleSpeech = () => {
        setCurrentSpeaker({ activeSpeakerId : id, activeText: text});
        toggleIsSpeaking(!isSpeaking);
        setIsSpeaking(!isSpeaking);
    };

    return (
        eaMode &&
        <div 
        tabIndex={0} 
        role="button" 
        className={`hover:shadow-lg transition flex-none border border-darkBlue w-9 rounded p-1 cursor-pointer mt-1 speaker ${isRight ? "float-right ml-3" : "float-left mr-3"}`} 
        onKeyDown={(e) => e.key === 'Enter' && toggleSpeech()} onClick={() => {toggleSpeech();}}>
            <img className='' src={`${basePath}/images/ico/ea-plain.svg`} alt="enhanced accessibility" />
            <div className='mt-1 hover:opacity-70  transition opacity-1 w-6 mx-auto'
            >
                {activeIsSpeaking && activeSpeakerId === id ?
                    <img src={`${basePath}/images/ico/pause.svg`} alt="enhanced accessibility" />

                    : <img src={`${basePath}/images/ico/play.svg`} alt="enhanced accessibility" />
                }
            </div>
        </div>
    )
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(Speaker);