import React, { lazy } from 'react';

// const HomePage = lazy(() => import('./pages/homePage/homePage'));
import HomePage from './pages/homePage/homePage';
const CompanyOverview = lazy(() => import('./pages/companyOverview/companyOverview'));
const OurMission = lazy(() => import('./pages/ourMissions/ourMissions'));
const LeadershipTeam = lazy(() => import('./pages/leadershipTeam/leadershipTeam'));
const Careers = lazy(() => import('./pages/careers/careers'));
const ComplianceAndEthics = lazy(() => import('./pages/complianceAndEthics/complianceAndEthics'));
const CoreSolutions = lazy(() => import('./pages/coreSolutions/coreSolutions'));
const CustomizedModularServices = lazy(() => import('./pages/bespokeFinancialServices/customizedModularServices'));
const RiskAssessment = lazy(() => import('./pages/bespokeFinancialServices/riskAssessment'));
const DataSecurityAndPrivacy = lazy(() => import('./pages/bespokeFinancialServices/dataSecurityAndPrivacy'));
const MarketingAndCustomerAcquisition = lazy(() => import('./pages/bespokeFinancialServices/marketingAndCustomerAcquisition'));
const FinancialManagement = lazy(() => import('./pages/bespokeFinancialServices/financialManagement'));
const ProductDevelopmentAndMarketAnalysis = lazy(() => import('./pages/bespokeFinancialServices/productDevelopmentAndMarketAnalysis'));
const CardIssuingAndFulfilment = lazy(() => import('./pages/bespokeFinancialServices/cardIssuingAndFulfilment'));
const WhiteLabelConsumerApp = lazy(() => import('./pages/bespokeFinancialServices/whiteLabelConsumerApp'));
const LoyaltyAndRewardsProgram = lazy(() => import('./pages/bespokeFinancialServices/loyaltyAndRewardsProgram'));
const CustomerMonitoring = lazy(() => import('./pages/bespokeFinancialServices/customerMonitoring'));
const DebtCollection = lazy(() => import('./pages/bespokeFinancialServices/debtCollection'));
const PaymentProcessing = lazy(() => import('./pages/bespokeFinancialServices/paymentProcessing'));
const UnderwritingAndLoanOrigination  = lazy(() => import('./pages/bespokeFinancialServices/underwritingAndLoanOrigination'));
const TailoredServicesOverview = lazy(() => import('./pages/tailoredServices/tailoredServicesOverview'));
const TailoredServicesBanks = lazy(() => import('./pages/tailoredServices/tailoredServicesBanks'));
const StrategicTechnologyIntegrationForBuildingSocieties = lazy(() => import('./pages/tailoredServices/strategicTechnologyIntegrationForBuildingSocieties'));
const CreditUnions = lazy(() => import('./pages/tailoredServices/creditUnions'));
const FinTechsCreditSolutions = lazy(() => import('./pages/tailoredServices/finTechsCreditSolutions'));
const LiquidityProvidersCreditSolutions = lazy(() => import('./pages/tailoredServices/liquidityProvidersCreditSolutions'));
const TailoredServicesRetailers = lazy(() => import('./pages/tailoredServices/tailoredServicesRetailers'));
const DiversityAndInclusion = lazy(() => import('./pages/diversityAndInclusion/diversityAndInclusion'));
const EsgFramework = lazy(() => import('./pages/esgFramework/esgFramework'));
const ContactUs = lazy(() => import('./pages/contactUs/contactUs'));
const PrivacyPolicy = lazy(() => import('./pages/policies/privacyPolicy'));
const TermsOfUse = lazy(() => import('./pages/policies/termsOfUse'));
const CookiePolicy = lazy(() => import('./pages/policies/cookiePolicy'));
const Disclaimer = lazy(() => import('./pages/policies/disclaimer'));
const PilotIntegrationProgram = lazy(() => import('./pages/pilotIntegrationProgram/pilotIntegrationProgram'));



const KnowledgeHub = lazy(() => import('./pages/knowledgeHub/knowledgeHub'));
const KnowledgeHubPost1 = lazy(() => import('./pages/knowledgeHub/knowledgeHubPost1'));
const KnowledgeHubPost2 = lazy(() => import('./pages/knowledgeHub/knowledgeHubPost2'));
const KnowledgeHubPost3 = lazy(() => import('./pages/knowledgeHub/knowledgeHubPost3'));
const KnowledgeHubPost4 = lazy(() => import('./pages/knowledgeHub/knowledgeHubPost4'));
const KnowledgeHubPost5 = lazy(() => import('./pages/knowledgeHub/knowledgeHubPost5'));

export const baseURL = '';
export const basePath = '../';

export const routes = [
  { pageName: 'Home', path: baseURL, element: <HomePage /> },
  { pageName: 'book_consultation', path: `${baseURL}/book_consultation`, element: <HomePage /> },
  { pageName: 'Company overview', path: `${baseURL}/company_overview`, element: <CompanyOverview /> },
  { pageName: 'The missions & values', path: `${baseURL}/our_mission`, element: <OurMission /> },
  { pageName: 'Leadership team', path: `${baseURL}/leadership_team`, element: <LeadershipTeam /> },
  { pageName: 'Compliance & Ethics', path: `${baseURL}/compliance_and_ethics`, element: <ComplianceAndEthics /> },
  { pageName: 'Careers', path: `${baseURL}/careers`, element: <Careers /> },
  { pageName: 'Core Solutions', path: `${baseURL}/core_solutions`, element: <CoreSolutions /> },
  { pageName: 'Customized Modular Services', path: `${baseURL}/customised_modular_services`, element: <CustomizedModularServices /> },
  { pageName: 'Risk Assessment & Credit Scoring', path: `${baseURL}/risk_assessment_and_credit_scoring`, element: <RiskAssessment /> },
  { pageName: 'Underwriting & Loan Origination', path: `${baseURL}/underwriting&loan_origination`, element: <UnderwritingAndLoanOrigination /> },
  { pageName: 'Data Security & Privacy', path: `${baseURL}/data_security&privacy`, element: <DataSecurityAndPrivacy /> },
  { pageName: 'Marketing & Customer Acquisition', path: `${baseURL}/marketing&customer_acquisition`, element: <MarketingAndCustomerAcquisition /> },
  { pageName: 'Financial Management', path: `${baseURL}/financial_management`, element: <FinancialManagement /> },
  { pageName: 'Product Development & Market Analysis', path: `${baseURL}/product_development&market_analysis`, element: <ProductDevelopmentAndMarketAnalysis /> },
  { pageName: 'Card Issuing & Fulfilment', path: `${baseURL}/card_issuing&fulfilment`, element: <CardIssuingAndFulfilment /> },
  { pageName: 'White Label Payment App', path: `${baseURL}/white_label_consumer_app`, element: <WhiteLabelConsumerApp /> },
  { pageName: 'Loyalty & Rewards Program', path: `${baseURL}/loyalty&rewards_program`, element: <LoyaltyAndRewardsProgram /> },
  { pageName: 'Customer Monitoring', path: `${baseURL}/custmomer_monitoring`, element: <CustomerMonitoring /> },
  { pageName: 'Debt Collection', path: `${baseURL}/debt_collection`, element: <DebtCollection /> },
  { pageName: 'Payment Processing', path: `${baseURL}/payment_processing`, element: <PaymentProcessing /> },
  { pageName: 'TailoredServices Overview', path: `${baseURL}/tailored_services_overview`, element: <TailoredServicesOverview /> },
  { pageName: 'TailoredServices Banks', path: `${baseURL}/tailored_services_banks`, element: <TailoredServicesBanks /> },
  { pageName: 'Strategic Technology Integration for Building Societies', path: `${baseURL}/strategic_technology_integration_for_building_societies`, element: <StrategicTechnologyIntegrationForBuildingSocieties /> },
  { pageName: 'Unlocking Growth and Innovation for Credit Unions with Blukite Finance', path: `${baseURL}/growth_and_innovation_for_credit_unions`, element: <CreditUnions /> },
  { pageName: 'Empowering FinTech Innovation: Credit Solutions by Blukite Finance', path: `${baseURL}/fin_techs_credit_solutions`, element: <FinTechsCreditSolutions /> },
  { pageName: 'Optimising Returns for Liquidity Providers: Credit Solutions by Blukite Finance', path: `${baseURL}/liquidity_providers_credit_solutions`, element: <LiquidityProvidersCreditSolutions /> },
  { pageName: 'Optimising Retail Expansion: Innovative Credit Solutions by Blukite Finance', path: `${baseURL}/tailored_services_retailers`, element: <TailoredServicesRetailers /> },
  { pageName: 'Diversity and Inclusion', path: `${baseURL}/diversity_and_inclusion`, element: <DiversityAndInclusion /> },
  { pageName: 'Esg', path: `${baseURL}/esg`, element: <EsgFramework /> },
  { pageName: 'Contact Us', path: `${baseURL}/contact_us`, element: <ContactUs /> },
  { pageName: 'Privacy Policy', path: `${baseURL}/privacy_policy`, element: <PrivacyPolicy /> },
  { pageName: 'Terms of use', path: `${baseURL}/terms_of_use`, element: <TermsOfUse /> },
  { pageName: 'Cookie Policy', path: `${baseURL}/cookie_policy`, element: <CookiePolicy /> },
  { pageName: 'Disclaimer', path: `${baseURL}/disclaimer`, element: <Disclaimer /> },
  { pageName: 'BluSys Pilot Integration Program', path: `${baseURL}/pilot_integration_program`, element: <PilotIntegrationProgram /> },
  { pageName: 'Knowledge Hub', path: `${baseURL}/knowledge_hub`, element: <KnowledgeHub /> },
  { pageName: 'Knowledge Hub', path: `${baseURL}/knowledge_hub/1`, element: <KnowledgeHubPost1 /> },
  { pageName: 'Knowledge Hub', path: `${baseURL}/knowledge_hub/2`, element: <KnowledgeHubPost2/>},
  { pageName: 'Knowledge Hub', path: `${baseURL}/knowledge_hub/3`, element: <KnowledgeHubPost3/>},
  { pageName: 'Knowledge Hub', path: `${baseURL}/knowledge_hub/4`, element: <KnowledgeHubPost4/>},
  { pageName: 'Knowledge Hub', path: `${baseURL}/knowledge_hub/5`, element: <KnowledgeHubPost5/>},
];
