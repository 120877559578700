// EA
export const SET_EA_MODE = "SET_EA_MODE";
export const SET_ZOOM = "SET_ZOOM";
export const SET_EA_INFO_OPEN = "SET_EA_INFO_OPEN";

// POPUP
export const OPEN_POPUP = "OPEN_POPUP";
export const CLOSE_POPUP = "CLOSE_POPUP";

// Speaker
export const SET_CURRENT_SPEAKER = "SET_CURRENT_SPEAKER";
export const SET_IS_SPEAKING = "SET_IS_SPEAKING";