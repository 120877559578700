import React, {useEffect, useRef, useState} from 'react';
import { connect } from 'react-redux';
import {setCurrentSpeaker, toggleIsSpeaking} from "../../actions/speaker";
import {useLocation} from "react-router-dom";

const mapStateToProps = state => ({
    ...state.speaker,
});

const mapDispatchToProps = dispatch => ({
    setCurrentSpeaker: (payload) => {
        return dispatch(setCurrentSpeaker(payload));
    },
    toggleIsSpeaking: (isSpeaking) => {
        return dispatch(toggleIsSpeaking(isSpeaking));
    }
})

const SpeakerController = ({activeSpeakerId, activeText, activeIsSpeaking, toggleIsSpeaking, setCurrentSpeaker}) => {

    // const [selectedVoiceName, setSelectedVoiceName] = useState('Google US English');
    const [selectedVoiceName, setSelectedVoiceName] = useState('Google UK English Female');
    const [allVoices, setAllVoices] = useState();
    const { pathname } = useLocation();

    const intervalRef = useRef(null); // Ref to store the interval ID
    const pausedRef = useRef(!activeIsSpeaking); // Ref to keep track of the latest value of paused

    useEffect(() => {
        const voices = window.speechSynthesis.getVoices();
        const englishVoices = voices.filter(function (voice) {
            return voice.lang === 'en-US' || voice.lang === 'en-GB';
        });
        setAllVoices(englishVoices)
    }, [])

    useEffect(() => {
        console.log("active speaker id changed", activeSpeakerId);
        window.speechSynthesis.cancel();

        if(activeText){
            speechText();
        }
    }, [activeSpeakerId]);

    useEffect(() => {
        pausedRef.current = !activeIsSpeaking;

        if(activeIsSpeaking) {
            window.speechSynthesis.pause();
            window.speechSynthesis.resume();
        }
        else {
            window.speechSynthesis.pause();
        }
    }, [activeIsSpeaking])

    useEffect(() => {
        window.speechSynthesis.cancel();
        toggleIsSpeaking(false);
        setCurrentSpeaker({ activeSpeakerId : null, activeText: null});
    }, [pathname]);

    window.speechSynthesis.onvoiceschanged = function () {
        const voices = window.speechSynthesis.getVoices();
        const englishVoices = voices.filter(function (voice) {
            return voice.lang === 'en-US' || voice.lang === 'en-GB';
        });
        setAllVoices(englishVoices)
    };

    function handleCustomPronunciation(word) {
        const pronunciationRules = {
            "BluSys": "Blusis",
            "BluSys®": "Blusis",
            "BluSys": "Blusis",
            "BluSys:": "Blusis. ",
        };

        if (pronunciationRules.hasOwnProperty(word)) {
            return pronunciationRules[word];
        } else {
            return word;
        }
    }

    const speechText = () => {
        const value = new SpeechSynthesisUtterance(activeText);
        let selectedVoice = null;

        for (let i = 0; i < allVoices.length; i++) {
            if (allVoices[i].name === selectedVoiceName) {
                selectedVoice = allVoices[i];
                break;
            }
        }

        // if (selectedVoice !== null) {
        //     value.voice = selectedVoice;
        //
        // } else {
        //     console.error('Voice not found:', selectedVoiceName);
        // }

        const words = activeText.split(" ");
        const modifiedWords = words.map(word => handleCustomPronunciation(word));
        const modifiedText = modifiedWords.join(" ");
        value.text = modifiedText;

        value.rate = 1

        window.speechSynthesis.cancel();
        window.speechSynthesis.speak(value);

        value.onerror = () => {
            console.log("on error");
        }

        value.onboundary = () => {
            console.log("on boundary");
        }

        value.onmark = () => {
            console.log("on onmark");
        }

        // value.onstart = (event) => {
        //     // console.log("on start");

        //     intervalRef.current = setInterval(() => {
        //         // console.log('paused in func', pausedRef.current)
        //         // console.log('paused in func', !activeIsSpeaking)

        //         if (pausedRef.current) {
        //             return;
        //         }

        //         if (!window.speechSynthesis.speaking) {
        //             clearInterval(intervalRef.current);
        //             console.log('clear in func');
        //         } else {
        //             // console.log('resume in func');
        //             window.speechSynthesis.pause();
        //             window.speechSynthesis.resume();
        //             console.log('resume in func');
        //         }

        //     }, 14000);
        // };

        value.onend = (event) => {
            clearInterval(intervalRef.current);

            toggleIsSpeaking(false);
            setCurrentSpeaker({ activeSpeakerId : -1, activeText: null});

            console.log('clear interval on end');
        };
    }

  return (
    <div>
    </div>
  )
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(SpeakerController);