import { combineReducers } from 'redux';
import ea from './ea';
import popups from './popup';
import speaker from './speaker';


const rootReducer = combineReducers({
    ea,
    popups,
    speaker
});

export default (state, action) =>
  rootReducer(action.type === 'USER_LOGOUT' ? undefined : state, action);