import {
   SET_CURRENT_SPEAKER, SET_IS_SPEAKING,
} from '../actions';

export default ( state = {
    activeSpeakerId: null,
    activeText: "",
    activeIsSpeaking: false
}, action) => {

   const {
       activeText,
       activeSpeakerId,
       isSpeaking
   } = action;

   switch (action.type) {
      
      case SET_CURRENT_SPEAKER: {
         return {
            ...state,
             activeText: activeText,
             activeSpeakerId: activeSpeakerId,
         }
      }

       case SET_IS_SPEAKING: {
           return {
               ...state,
               activeIsSpeaking: isSpeaking,
           }
       }

      default:
            return state;
   }
}