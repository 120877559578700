import {
   SET_EA_MODE,
   SET_ZOOM,
} from '../actions';

export default ( state = {
   eaMode: false,
   zoom: '100%',
   eaInfoOpen: false
}, action) => {

   const {
      eaMode,
      zoom,
      eaInfoOpen
   } = action;

   switch (action.type) {
      
      case SET_EA_MODE: {
         return {
            ...state,
            eaMode: eaMode
         }
      }

      case SET_ZOOM: {
         return {
            ...state,
            zoom: zoom
         }
      }

      default:
            return state;
   }
}