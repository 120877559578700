import React, { useEffect, useState } from 'react';
import { basePath } from '../../routes';

const Loader = () => {
  // const [showLoader, setShowLoader] = useState(true);

  // useEffect(() => {
  //   document.body.style.overflow = 'hidden';

  //   const timer = setTimeout(() => {
  //     setShowLoader(false);
  //     document.body.style.overflow = 'auto';
  //   }, 4500);

  //   return () => {
  //     clearTimeout(timer);
  //     document.body.style.overflow = 'auto';
  //   };
  // }, []);

  // if (!showLoader) return null;

  return (
    <div className='loaderWrapper'>
      <div className='loader'>
        <video className='w-full h-full' autoPlay muted loop playsInline>
          <source src={`${basePath}/videos/loader.mp4`} type="video/mp4" />
        </video>
      </div>
    </div>
  );
};

export default Loader;
