import {
    SET_CURRENT_SPEAKER, SET_IS_SPEAKING,
} from './index';
 
 
 export const setCurrentSpeaker = (payload) => {
    const { activeSpeakerId, activeText } = payload;
 
    return {
        type: SET_CURRENT_SPEAKER,
        activeSpeakerId,
        activeText
    }
 }

export const toggleIsSpeaking = (isSpeaking) => {
    return {
        type: SET_IS_SPEAKING,
        isSpeaking
    }
}