import React from 'react';
import { connect } from 'react-redux';
import { Modal } from 'antd';
import BookConsultation from '../popups/bookConsultation';

import {
   closePopup,
} from '../../actions/popup';

const mapStateToProps = state => ({
   ...state.popups
});

const mapDispatchToProps = dispatch => ({
   closePopup: () => {
      return dispatch(closePopup());
   },
})

const Popup = (props) => {

   const theme = localStorage.getItem("theme");

   const handleClose = () => {
      props.closePopup();
   }

   const map = {
      "BookConsultation": BookConsultation,
   }

   const CurrentPopup = map[props.currentPopup];

   return (
      <Modal
         open={props.showPopup}
         footer={null}
         className='popup'
         width={600}
         onCancel={handleClose}
      >
         <CurrentPopup info={props.info} />

      </Modal>
   )
}

export default connect(
   mapStateToProps,
   mapDispatchToProps
)(Popup);