import React, { useState, useEffect } from 'react';
import { Button, Form, Input, Select, notification, Spin, Empty } from 'antd';
import { pagesContent } from '../../pagesContent';
import { PlainButton, SearchButton } from '../buttons/buttons';
import { basePath } from '../../routes';
import { NavLink, useLocation } from "react-router-dom";
import './search.scss';

const Search = ({ closeSearch }) => {

  const [searchValue, setSearchValue] = useState('');
  const [searchResults, setSearchResults] = useState([]);
  const [isSearching, setIsSearching] = useState(false);

  useEffect(() => {
    setIsSearching(false)
  }, [searchValue])

  const handleKeyDown = (event) => {
    if (event.key === 'Enter') {
      handleSearch()
    }
  }

  const handleSearch = () => {
    if (!searchValue) return;
    setIsSearching(true);
    const searchWords = searchValue.toLowerCase().split(/\s+/); // Split the search value into words
    const filteredPages = pagesContent.map(page => {
      const sentences = page.content.split('.').map(sentence => sentence.trim());
      const matchingSentence = sentences.find(sentence =>
        searchWords.every(word => sentence.toLowerCase().includes(word)) // Check if every search word is in the sentence
      );
      if (matchingSentence) {
        const words = matchingSentence.split(/\s+/); // Split the sentence into words
        const highlightedWords = words.map(word => {
          if (searchWords.some(searchWord => word.toLowerCase().includes(searchWord))) {
            return `<span class="text-blue">${word}</span>`; // Highlight the matching word
          }
          return word;
        });
        const highlightedSentence = highlightedWords.join(' '); // Combine words
        return {
          ...page,
          match: highlightedSentence
        };
      }
      return null;
    }).filter(Boolean);
    setSearchResults(filteredPages);
  };


  return (
    <div className='search p-5 pr-5 sm:pr-0 w-full sm:w-6/12 '>
      <div className='flex gap-3 sm:gap-5'>
        <div className='block sm:hidden w-full'>
          <Input value={searchValue} onKeyDown={handleKeyDown} onChange={(e) => setSearchValue(e.target.value)} size='large' placeholder='Search ...' />
        </div>
        <div className='hidden sm:block w-full'>
          <Input value={searchValue} onKeyDown={handleKeyDown} onChange={(e) => setSearchValue(e.target.value)} autoFocus size='large' placeholder='Search ...' />
        </div>
        <div className='w-min'>
          <SearchButton onClick={handleSearch} text="Search" />
        </div>
      </div>
      <div className='mt-2 py-3 search__resultItems'>
        {searchResults.length > 0 ? searchResults.map(x => {
          return (
            <NavLink
              onClick={closeSearch}
              key={x.id}
              to={x.path}
              className='search__resultItem font-normal text-lg px-3 py-3 block border-b border-separateLight'
            >
              <div className='flex justify-between items-center'>
                <div>
                  <p className='search__resultTitle text-base sm:text-lg'>{x.title}</p>
                  <p className='search__resultDesc -mt-1 text-gray text-sm sm:text-base' dangerouslySetInnerHTML={{ __html: x.match }}></p>
                </div>
                <img className='p-3' src={`${basePath}/images/ico/customizedModularServicesCard_arrow.svg`} alt="arrow" />
              </div>

            </NavLink>
          )
        })
          : isSearching &&
          <Empty description="No match" />
        }
      </div>
    </div>
  )
}

export default Search